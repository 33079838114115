<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid"
        title="설비 사양"
        :isTitle="true"
        tableId="grid"
        :columns="grid.columns"
        :data="grid.data"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
        :hideBottom="true"
        :isExcelDown="false"
        :gridHeightAuto="true"
        :usePaging="false"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
              <!-- <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addrow" />
              <c-btn v-if="editable" label="삭제" icon="remove" @btnClicked="deleteSpec" />
              <c-btn v-if="editable" label="저장" icon="save" @btnClicked="saveSpec" /> -->
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <!-- <q-btn-group outline >
          <c-btn :flat="true" v-if="editable" label="추가" icon="add" @btnClicked="addrow" />
          <c-btn :flat="true" v-if="editable" label="삭제" icon="remove" @btnClicked="deleteSpec" />
          <c-btn :flat="true" v-if="editable" label="저장" icon="save" @btnClicked="saveSpec" />
        </q-btn-group> -->
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-spec',
  props: {
    param: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        equipmentNo: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
        equipmentName: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'item',
            field: 'item',
            label: '항목',
            style: 'width:120px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'itemDesc',
            field: 'itemDesc',
            label: '항목설명',
            style: 'width:150px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'itemValue',
            field: 'itemValue',
            label: '값',
            align: 'center',
            style: 'width:60px',
            sortable: false,
          },
          {
            name: 'itemUnit',
            field: 'itemUnit',
            label: '단위',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    param() {
      this.getList()
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.equipment.spec.list.url;
      this.saveUrl = transactionConfig.mdm.equipment.spec.save.url;
      this.deleteUrl = transactionConfig.mdm.equipment.spec.delete.url;
      this.getList();
    },
    getList() {
      if (this.param.equipmentCd) {
        this.$http.url = this.listUrl;
        this.$http.param = {plantCd: this.param.plantCd, equipmentCd: this.param.equipmentCd};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    saveSpec() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.saveUrl;
            this.$http.type = 'POST';
            this.$http.param = this.grid.data;
            this.$http.request(() => {
              this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addrow() {
      // 초기에는 userId에 tempId를 부여
      this.popupOptions.title = '설비유형별 설비특성 검색'; // 설비 검색
      this.popupOptions.param = {
        type: 'multiple',
        equipmentCd: this.param.equipmentCd
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentClassSpecPop.vue'}`);
      this.popupOptions.width = '99%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipmentSpecPopup;

    },
    closeEquipmentSpecPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.grid.data, {
            item: _item.item,
          });
          if (index === -1) {
            this.grid.data.push({
              plantCd: this.param.plantCd,
              equipmentCd: this.param.equipmentCd,
              equipmentSpecId: uid(),
              orderNo: '0',
              item: _item.item,
              itemDesc: _item.itemDesc,
              itemValue: _item.itemValue,
              itemUnit: _item.itemUnit,
              editFlag: 'C',
            })
          }
        })
      }
    },
    deleteSpec() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    closePopUps() {
      this.$emit('closePopup')
    },
  }
};
</script>